import React from "react";
import { motion } from "framer-motion";

const About = () => {
  return (
    <div name="about" className=" w-full h-screen text-gray-300">
      <motion.div className=" flex flex-col justify-center items-center w-full h-full">
        <div className=" max-w-[1000px] w-full grid grid-cols-2 gap-8 ">
          <div className=" pb-8 pl-4">
            <motion.p
              className=" text-4xl font-bold inline border-b-4 border-[#b9c9ff]"
              initial={{ x: -200, opacity: 1 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 1 }}
            >
              About
            </motion.p>
          </div>
        </div>
        <div className=" max-w-[1000px] w-full grid  gap-8 px-4">
          <div className="  text-4xl font-bold">
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Hi, nice to meet you
            </motion.p>
          </div>
          <div>
            <motion.p
              initial={{ x: -200, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.5 }}
              className="text-lg"
            >
              With years of business experience I have developed into a lateral
              thinker, a highly proficient team leader and an excellent
              communicator, capable working with others or individually. I am
              eager to use all my previous experiences to help deliver desirable
              outcomes for any project I undertake.
            </motion.p>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default About;
