import React, { useState, useEffect } from "react";
import moon from "../assets/moon.png";

const Contact = () => {
  const [mousePos, setMousePos] = useState({});

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePos({
        xValue: event.clientX - window.innerWidth / 2,
        yValue: event.clientY - window.innerHeight / 2,
        rotateDegree: (event.clientX / (window.innerWidth / 2)) * 20,
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  return (
    <div
      name="contact"
      className=" w-full h-screen flex justify-center items-center p-4"
    >
      <img
        src={moon}
        alt="moon"
        className="hidden md:block absolute opacity-40 left-[-5%]
          -z-10  "
        style={{
          transform: `perspective(2300px) 
        
          
        translateX(calc(${-mousePos.xValue * 0.013}px)) 
        translateY(calc(${mousePos.yValue * 0.013}px))`,
        }}
      />
      <form
        method="post"
        action="https://getform.io/f/d7ced37f-8550-4ac1-ae03-cd6eefc5cf59"
        className=" flex flex-col max-w-[600px] w-full"
      >
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-[#b9c9ff] text-gray-300">
            Contact
          </p>
          <p className="text-gray-300 py-4">
            Submit the form below or contact via email -{" "}
            <a
              className=" cursor-pointer"
              href="mailto:tajdinov@gmail.com.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              tajdinov@gmail.com
            </a>
          </p>
        </div>
        <input
          className="p-2 bg-[ccd6f6]"
          type="text"
          placeholder="Name"
          name="name"
          required
        />
        <input
          className="my-4 p-2 bg-[ccd6f6]"
          type="email"
          placeholder="Email"
          name="email"
          required
        />
        <textarea
          className="p-2 bg-[ccd6f6]"
          name="message"
          rows="8"
          placeholder="Message"
          required
        ></textarea>
        <button className="text-white border-2 hover:bg-[#b9c9ff] hover:border-[#b9c9ff] px-4 py-3 my-8 mx-auto flex items-center">
          Send
        </button>
      </form>
    </div>
  );
};

export default Contact;
