import React, { useState, useEffect, Suspense } from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import Skills from "./components/Skills";
import Work from "./components/Work";
import Contact from "./components/Contact";
import Loader from "./components/Loader";

function App() {
  const [done, setDone] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDone(true);
    }, 2000);
  }, []);

  return (
    <>
      <Suspense fallback={<Loader />}>
        {!done ? (
          <Loader />
        ) : (
          <>
            <Navbar />
            <Home />
            <About />
            <Skills />
            <Work />
            <Contact />
          </>
        )}
      </Suspense>
    </>
  );
}

export default App;
