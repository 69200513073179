import React, {
  Suspense,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link, animateScroll as scroll } from "react-scroll";
import { FaGithub, FaLinkedin, FaInstagram } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";

import { gsap } from "gsap";

import layer1 from "../assets/layer 1.png";
import layer2 from "../assets/layer 2.png";
import layer3 from "../assets/layer 3.png";
import layer4 from "../assets/layer 4.png";
import layer5 from "../assets/layer 5.png";
import layer6 from "../assets/layer 6.png";

const Home = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  const app = useRef();

  const textRef = useRef();
  const nameRef = useRef();
  const layer1Ref = useRef();
  const layer2Ref = useRef();
  const layer3Ref = useRef();
  const layer4Ref = useRef();
  const layer5Ref = useRef();
  const layer6Ref = useRef();
  const romanRef = useRef();
  const devRef = useRef();
  const buttonRef = useRef();
  const socialRef = useRef();

  const comp = useRef();

  useLayoutEffect(() => {
    // create our context. This function is invoked immediately and all GSAP animations and ScrollTriggers created during the execution of this function get recorded so we can revert() them later (cleanup)
    let ctx = gsap.context(() => {
      gsap.from(textRef.current, {
        x: 450,
        opacity: 0,
        duration: 1,
        delay: 1,
      });
      gsap.from(nameRef.current, {
        x: 450,
        opacity: 0,
        duration: 1,
        delay: 1,
      });
      gsap.from(romanRef.current, {
        y: 450,
        opacity: 0,
        duration: 1,
        delay: 1,
      });
      gsap.from(devRef.current, {
        y: 450,
        opacity: 0,
        duration: 1,
        delay: 1,
      });
      gsap.from(layer1Ref.current, { y: 450, opacity: 0, duration: 1.6 });
      gsap.from(layer2Ref.current, {
        x: 450,

        opacity: 0,
        duration: 1.8,
      });
      gsap.from(layer3Ref.current, {
        x: -450,
        y: 300,
        opacity: 0,
        duration: 1.4,
      });
      gsap.from(layer4Ref.current, {
        y: -450,
        x: -350,
        opacity: 0,
        duration: 1.3,
      });
      gsap.from(layer5Ref.current, {
        y: 350,
        x: 350,
        opacity: 0,
        duration: 2,
      });
      gsap.from(layer6Ref.current, { opacity: 0, duration: 2.5 });
      gsap.from(buttonRef.current, { opacity: 0, duration: 3.5, delay: 2.5 });
      gsap.from(socialRef.current, {
        x: -100,
        duration: 1,
        delay: 1.5,
      });
    }, comp); // <- IMPORTANT! Scopes selector text

    return () => ctx.revert(); // cleanup
  }, []);

  const [mousePos, setMousePos] = useState({});

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePos({
        xValue: event.clientX - window.innerWidth / 2,
        yValue: event.clientY - window.innerHeight / 2,
        rotateDegree: (event.clientX / (window.innerWidth / 2)) * 20,
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <>
      <div
        name="home"
        ref={app}
        className="w-full h-screen overflow-hidden text-gray-300"
      >
        {/* Social icons */}
        <div
          ref={socialRef}
          className="hidden lg:flex fixed flex-col top-[30%] z-50"
        >
          <ul>
            <li className="w-[160px] h-[60px] z-80 flex justify-between items-center ml-[-100px] hover:ml-[-10px]  duration-300 bg-gradient-to-r from-blue-600 rounded-md">
              <a
                className="flex justify-between items-center w-full text-grey-300 z-50 "
                href="https://www.linkedin.com/in/roman-tazhdynov-bb647b86"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn <FaLinkedin size={30} />
              </a>
            </li>
            <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-gradient-to-r from-[#333333] rounded-md">
              <a
                className="flex justify-between items-center w-full text-grey-300"
                href="https://github.com/tajdinov"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub <FaGithub size={30} />
              </a>
            </li>
            <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-gradient-to-r from-[#fa7e1e] via-[#962fbf] rounded-md">
              <a
                className="flex justify-between items-center w-full text-grey-300"
                href="https://www.instagram.com/tajdinov/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram <FaInstagram size={30} />
              </a>
            </li>
            <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-gradient-to-r from-[#6fc2b0] rounded-md">
              <a
                className="flex justify-between items-center w-full text-grey-300"
                href="mailto:tajdinov@gmail.com.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Email <HiOutlineMail size={30} />
              </a>
            </li>
            <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-gradient-to-r from-[#565f69] rounded-md">
              <a
                className="flex justify-between items-center w-full text-grey-300"
                href="https://drive.google.com/file/d/1orucO7djSn1wqm1ElQjLXI69TKZmkGy-/view?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
              >
                Resume <BsFillPersonLinesFill size={30} />
              </a>
            </li>
          </ul>
        </div>
        {/* container */}
        <div className="max-v-[1000px] mx-auto px-8 flex flex-col justify-center h-full lg:pl-[200px] overflow-hidden">
          <img
            ref={layer2Ref}
            src={layer2}
            alt="layer2"
            className="hidden md:block absolute z-0 right-0 bottom-0  opacity-40 parallax"
            style={{
              transform: `perspective(2300px) 
            
            rotateY(${mousePos.rotateDegree * 0}deg)
            translateX(calc(${-mousePos.xValue * 0.01}px)) 
            translateY(calc(${mousePos.yValue * 0.01}px))`,
            }}
          />
          <img
            ref={layer6Ref}
            src={layer6}
            alt="layer6"
            className="hidden md:block absolute z-0 right-0 bottom-0  opacity-40 parallax"
            style={{
              transform: `perspective(2300px) 
            
            rotateY(${mousePos.rotateDegree * 0}deg)
            translateX(calc(${-mousePos.xValue * 0.013}px)) 
            translateY(calc(${mousePos.yValue * 0.013}px))`,
            }}
          />

          <img
            ref={layer4Ref}
            src={layer4}
            alt="layer4"
            className="hidden md:block absolute top-0 left-0 opacity-40 transition-none pointer-events-none z-10 parallax"
            style={{
              transform: `perspective(2300px) 
            
            rotateY(${mousePos.rotateDegree * 0}deg)
            translateX(calc(${-mousePos.xValue * 0.015}px)) 
            translateY(calc(${mousePos.yValue * 0.015}px))`,
            }}
          />
          <p
            ref={nameRef}
            className="text-[#dee5fe] text-lg parallax"
            style={{
              transform: `perspective(2300px) 
                  
                  rotateY(${mousePos.rotateDegree * 0}deg)
                  translateX(calc(${-mousePos.xValue * 0.002}px)) 
                  translateY(calc(${mousePos.yValue * 0.002}px))`,
            }}
          >
            Hi, my name is
          </p>
          <h1
            ref={romanRef}
            className=" text-4xl sm:text-7xl font-bold  z-20 parallax"
            style={{
              transform: `perspective(2300px) 
                    
                    rotateY(${mousePos.rotateDegree * 0}deg)
                    translateX(calc(${-mousePos.xValue * 0.001}px)) 
                    translateY(calc(${mousePos.yValue * 0.001}px))`,
            }}
          >
            Roman Tazhdynov
          </h1>
          <h2
            ref={devRef}
            className=" text-4xl sm:text-7xl font-bold  z-20 parallax"
            style={{
              transform: `perspective(2300px) 
                    
                    rotateY(${mousePos.rotateDegree * 0}deg)
                    translateX(calc(${-mousePos.xValue * 0.002}px)) 
                    translateY(calc(${mousePos.yValue * 0.002}px))`,
            }}
          >
            I'm a full stack developer.
          </h2>
          <p
            ref={textRef}
            className=" ext-[#8892b0] py-4 max-w-[700px] text-[#a8b3d4] text-lg z-20"
            style={{
              transform: `perspective(2300px) 
                    
                    rotateY(${mousePos.rotateDegree * 0}deg)
                    translateX(calc(${-mousePos.xValue * 0.003}px)) 
                    translateY(calc(${mousePos.yValue * 0.003}px))`,
            }}
          >
            Aspiring full-stack web developer utilising marketing and business
            background to build a more intuitive user experience on the web.
          </p>

          <img
            ref={layer3Ref}
            src={layer3}
            alt="layer3"
            className="hidden md:block absolute z-30 left-0 opacity-50"
            style={{
              transform: `perspective(2300px) 
            
            rotateY(${mousePos.rotateDegree * 0}deg)
            translateX(calc(${-mousePos.xValue * 0.02}px)) 
            translateY(calc(${mousePos.yValue * 0.02}px))`,
            }}
          />
          <img
            ref={layer5Ref}
            src={layer5}
            alt="layer5"
            className="hidden md:block absolute z-30 left-0 opacity-50"
            style={{
              transform: `perspective(2300px) 
            
            rotateY(${mousePos.rotateDegree * 0}deg)
            translateX(calc(${-mousePos.xValue * 0.022}px)) 
            translateY(calc(${mousePos.yValue * 0.022}px))`,
            }}
          />

          <img
            ref={layer1Ref}
            src={layer1}
            alt="layer1"
            className="hidden md:block  absolute z-40 bottom-[-170px] right-0 sm:left-0  "
            style={{
              transform: `perspective(2300px) 
            
            rotateY(${mousePos.rotateDegree * 0}deg)
            translateX(calc(${-mousePos.xValue * 0.025}px)) 
            translateY(calc(${mousePos.yValue * 0.025}px))`,
            }}
          />
          <div
            ref={buttonRef}
            className=" z-40"
            style={{
              transform: `perspective(2300px) 
                  
                  rotateY(${mousePos.rotateDegree * 0}deg)
                  translateX(calc(${-mousePos.xValue * 0.001}px)) 
                  translateY(calc(${mousePos.yValue * 0.001}px))`,
            }}
          >
            <Link onClick={handleClick} to="work" smooth={true} duration={500}>
              <button className="text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-[#b9c9ff] hover:border-[b9c9ff]">
                My Work
                <span className=" group-hover:rotate-90 duration-300">
                  <HiArrowNarrowRight className="ml-3" />
                </span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
