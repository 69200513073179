import React, { useState, useEffect } from "react";
import Logo from "../assets/RT.png";

import {
  FaBars,
  FaTimes,
  FaGithub,
  FaLinkedin,
  FaInstagram,
} from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";

import { Link, animateScroll as scroll } from "react-scroll";
import { motion } from "framer-motion";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div
        className="background-triangles md:hidden"
        style={{ transform: `translateY(calc(${offsetY * 0.5}px))` }}
      ></div>
      <div className="fixed w-full h-[80px]  bg-gradient-to-b from-[#0a192f] flex justify-between  items-center px-4 text-gray-300 z-50 ">
        <div>
          <Link to="home" smooth={true} duration={500}>
            <img
              className="ml-[-30px] flex cursor-pointer w-[100px] md:w-[150px] "
              src={Logo}
              alt="LogoImage"
            />
          </Link>
        </div>
        {/* menu */}
        <ul className="hidden lg:flex ">
          <li className="hover:text-gray-100">
            <Link to="home" smooth={true} duration={500}>
              Home
            </Link>
          </li>
          <li className="hover:text-gray-100">
            <Link to="about" smooth={true} duration={500}>
              About
            </Link>
          </li>
          <li className="hover:text-gray-100">
            <Link to="skills" smooth={true} duration={500}>
              Skills
            </Link>
          </li>
          <li className="hover:text-gray-100">
            <Link to="work" smooth={true} duration={500}>
              Projects
            </Link>
          </li>
          <li className="hover:text-gray-100">
            <Link to="contact" smooth={true} duration={500}>
              Contact
            </Link>
          </li>
        </ul>
        {/* Hamburger */}
        <div onClick={handleClick} className="lg:hidden z-[60] cursor-pointer">
          {!nav ? <FaBars /> : <FaTimes />}
        </div>
        {/* Mobile menu */}
        <motion.ul
          initial={{ x: 100, opacity: 1 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 40,
            duration: 0.01,
          }}
          className={
            !nav
              ? "hidden"
              : " lg:hidden absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center z-50"
          }
        >
          <motion.li
            initial={{ x: 200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              type: "spring",
              stiffness: 60,
              duration: 0.01,
              ease: "easeInOut",
            }}
            className="py-6 text-4xl hover:text-gray-100"
          >
            <Link onClick={handleClick} to="home" smooth={true} duration={500}>
              Home
            </Link>
          </motion.li>
          <motion.li
            initial={{ x: 200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              delay: 0.05,
              type: "spring",
              stiffness: 60,
              duration: 0.01,
              ease: "easeInOut",
            }}
            className="py-6 text-4xl hover:text-gray-100"
          >
            <Link onClick={handleClick} to="about" smooth={true} duration={500}>
              About
            </Link>
          </motion.li>
          <motion.li
            initial={{ x: 200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              delay: 0.1,
              type: "spring",
              stiffness: 60,
              duration: 0.01,
              ease: "easeInOut",
            }}
            className="py-6 text-4xl hover:text-gray-100"
          >
            <Link
              onClick={handleClick}
              to="skills"
              smooth={true}
              duration={500}
            >
              Skills
            </Link>
          </motion.li>
          <motion.li
            initial={{ x: 200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              delay: 0.15,
              type: "spring",
              stiffness: 60,
              duration: 0.01,
              ease: "easeInOut",
            }}
            className="py-6 text-4xl hover:text-gray-100"
          >
            <Link onClick={handleClick} to="work" smooth={true} duration={500}>
              Projects
            </Link>
          </motion.li>
          <motion.li
            initial={{ x: 200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              delay: 0.2,
              type: "spring",
              stiffness: 60,
              duration: 0.01,
              ease: "easeInOut",
            }}
            className="py-6 text-4xl hover:text-gray-100"
          >
            <Link
              onClick={handleClick}
              to="contact"
              smooth={true}
              duration={500}
            >
              Contact
            </Link>
          </motion.li>
          <motion.li
            className="flex justify-between items-center  py-6 text-4xl "
            initial={{ x: 200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{
              delay: 0.25,
              type: "spring",
              stiffness: 60,
              duration: 0.01,
              ease: "easeInOut",
            }}
          >
            <a
              className="px-3 hover:text-gray-100 hover:scale-150 hover:-rotate-3 duration-300"
              href="https://github.com/tajdinov"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub size={20} />
            </a>
            <a
              className="px-3 hover:text-gray-100 hover:scale-150 hover:rotate-3 duration-300 "
              href="https://www.linkedin.com/in/roman-tazhdynov-bb647b86"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin size={20} />
            </a>
            <a
              className="px-3 hover:text-gray-100 hover:scale-150 hover:-rotate-3 duration-300 "
              href="https://www.instagram.com/tajdinov/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram size={20} />
            </a>
            <a
              className="px-3 hover:text-gray-100 hover:scale-150 hover:rotate-3 duration-300 "
              href="mailto:tajdinov@gmail.com.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <HiOutlineMail size={20} />
            </a>

            <a
              className="px-3 hover:text-gray-100 hover:scale-150 hover:-rotate-3 duration-300 "
              href="https://drive.google.com/file/d/1orucO7djSn1wqm1ElQjLXI69TKZmkGy-/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsFillPersonLinesFill size={20} />
            </a>
          </motion.li>
        </motion.ul>
      </div>
    </>
  );
};

export default Navbar;
