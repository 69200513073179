import React from "react";
import { motion } from "framer-motion";

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const loadingCircleVariants = {
  start: {
    y: "0%",
  },
  end: {
    y: "100%",
  },
};
const loadingCircleTransition = {
  duration: 2.4,
  repeat: Infinity,
  ease: "easeInOut",
};

const Loader = () => {
  return (
    <div>
      <div className="fixed  w-full min-h-screen z-50 " />
      <div className="flex fixed w-full justify-center items-center h-screen">
        <motion.div className=" flex w-[4rem] h-[4rem] justify-around ">
          <motion.span
            className=" block w-[1rem] h-[1rem] rounded-full bg-gray-300"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 1,
              repeat: Infinity,
              ease: [0.075, 0.82, 0.165, 1],
              repeatDelay: 1,
            }}
          ></motion.span>
          <motion.span
            className=" block w-[1rem] h-[1rem] rounded-full bg-gray-300"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 1,
              repeat: Infinity,
              ease: [0.075, 0.82, 0.165, 1],
              repeatDelay: 1,
              delay: 0.5,
            }}
          ></motion.span>
          <motion.span
            className=" block w-[1rem] h-[1rem] rounded-full bg-gray-300"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 1,
              repeat: Infinity,
              ease: [0.075, 0.82, 0.165, 1],
              repeatDelay: 1,
              delay: 1,
            }}
          ></motion.span>
        </motion.div>
      </div>
    </div>
  );
};

export default Loader;
